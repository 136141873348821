/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { getMetadata } from '../../api';
import _ from 'lodash'
import { useParams } from 'react-router-dom';
import './index.scss'
// import backgroundImage from '../../assets/img/2090.jpg';


const Canvas = () => {
  const canvasRef = useRef(null);
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    draw()
  }, []);






  // const loadImage = (ctx: any, url: string, metaData: any) => {
  //   const canvas: any = canvasRef.current;
  //   const img = new Image();
  //   let isLoading = false; // 加载状态

  //   const load = () => {
  //     isLoading = true;
  //     img.src = url;
  //   };

  //   img.onload = () => {
  //     isLoading = false;
  //     ctx.drawImage(img, 40, 90, 165, 165);

  //     ctx.fillStyle = '#000';
  //     ctx.font = '700 60px Arial';
  //     ctx.fontWeight = '100'
  //     ctx.fillText(`${_.find(metaData.attributes, ['trait_type', 'Gender']).value}`, 50, 100);
  //   };

  //   img.onerror = () => {
  //     isLoading = false;
  //     // 2秒后重新加载图片
  //     setTimeout(load, 2000);
  //   };

  //   if (!isLoading) {
  //     load();
  //   }
  // };


  const drawRoundRect = (ctx: { beginPath: () => void; moveTo: (arg0: any, arg1: any) => void; lineTo: (arg0: number, arg1: number) => void; quadraticCurveTo: (arg0: any, arg1: any, arg2: number, arg3: number) => void; closePath: () => void; }, x: any, y: any, width: any, height: any, radius: number) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
  }
  const draw = async () => {
    const metaData: any = await getMetadata(`https://nft-sbt-test.s3.amazonaws.com/json/${id ? id : 0}.json`)
    const canvas: any = canvasRef.current;
    const ctx = canvas.getContext('2d');
    // 设置圆角
    const radius = 20;

    // 创建渐变
    const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
    gradient.addColorStop(0, '#FF84FF');
    gradient.addColorStop(1, '#15B6FC');

    // 绘制圆角矩形
    ctx.fillStyle = '#5E5985'; // 背景色
    ctx.strokeStyle = gradient; // 边框颜色
    ctx.lineWidth = 8; // 边框宽度
    ctx.beginPath();
    ctx.moveTo(radius + 4, 4);
    ctx.lineTo(canvas.width - radius, 4);
    ctx.arc(canvas.width - radius - 4, radius + 4, radius, -Math.PI / 2, 0);
    ctx.lineTo(canvas.width - 4, canvas.height - radius - 4);
    ctx.arc(canvas.width - radius - 4, canvas.height - radius - 4, radius, 0, Math.PI / 2);
    ctx.lineTo(radius + 4, canvas.height - 4);
    ctx.arc(radius + 4, canvas.height - radius - 4, radius, Math.PI / 2, Math.PI);
    ctx.lineTo(4, radius + 4);
    ctx.arc(radius + 4, radius + 4, radius, Math.PI, -Math.PI / 2);
    ctx.closePath();
    ctx.stroke();
    ctx.fill();
    // let image = new Image();
    // image.src = metaData.image;
    // await loadImage(ctx, metaData.image, metaData)
    drawRoundRect(ctx, 40, 90, 165, 165, 20);
    ctx.fillStyle = gradient;
    ctx.fill();

    const img = new Image();
    img.src = metaData.image;
    img.onload = function () {
      setIsLoading(false)
      drawRoundRect(ctx, 42, 92, 161, 161, 20);
      ctx.clip();
      ctx.drawImage(img, 42, 92, 161, 161);
    };

    ctx.fillStyle = '#333140';
    ctx.font = '700 20px Arial';

    ctx.fillText(`Name: ${_.find(metaData.attributes, ['trait_type', 'Gender']).value}`, 20, 30);
    ctx.fillText(`Grade: Lv.${_.find(metaData.attributes, ['trait_type', 'Grade']).value}`, 20, 50);
    ctx.fillText(`Birthday: ${_.find(metaData.attributes, ['trait_type', "Birthday"]).value}`, 20, 70);

    ctx.font = '700 28px Arial';
    ctx.fillText(`NUWA SBT`, 70, 330);
  };

  return (
    <>
      <div className="home">
        <div className={['loading', isLoading ? '' : 'hideLoading'].join(' ')}>
          <div className="spinner" >
            <img className="animate-spin-slowing" src={require('../../assets/img/spinner-white.svg').default} alt="" width="60" height="60" />
          </div>
        </div>
        <div
          style={{
            // display: 'flex',
            // alignItems: 'center',
            // width: '100%',
            // justifyContent: 'center',
            // minHeight: '100vh',
            // background: '#000'
          }}
        >
          <canvas ref={canvasRef} width={240} height={355} style={{
            marginTop: '-100px'
          }} />
        </div>
      </div>
    </>
  );
};

export default Canvas;
